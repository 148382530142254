import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { CapGroupPageLayout, Disclosures, Panel } from '../../components'
import { groupBy } from '../../utilities'

function getResources(data) {

  const resourceLinks = data.allFund.edges.map(e => ({...e.node, sortIndex: e.node.sortIndex + 10000})).map(f => f.resourceLinks.map(r => ({ ...r, fund: f }))).concat(
                        data.allOtherFund.edges.map(e => ({...e.node, sortIndex: e.node.sortIndex + 20000})).map(f => f.resourceLinks.map(r => ({ ...r, fund: f }))));
  const flatResourceLinks = resourceLinks.reduce((a,b) => a.concat(b), []);
  const grouped = groupBy(flatResourceLinks, 'link');
  const groupedArray = Object.keys(grouped).map(k => ({ key: k, links: grouped[k], sortIndex: Math.min(...grouped[k].map(r => r.fund.sortIndex * 10 + r.sortIndex)) }));
  groupedArray.sort((a,b) => a.sortIndex - b.sortIndex);

  
  const resources = [];

  const common = groupedArray.filter(r => r.links.length > 1);
  // temporary dirty hard code
  common.push({ links: [ { label: 'Semi-Annual Report 2023', link: 'https://assets.millimanfunds.com/Semi_Annual_Report_CG_20230828.pdf' }]});
  common.push({ links: [ { label: 'Annual Report 2023', link: 'https://assets.millimanfunds.com/Annual_Report_CG_20240306.pdf' }]});
  // common.push({ links: [ { label: 'December Funds Rate Range Supplement (New Funds)', link: 'https://assets.millimanfunds.com/December_Funds_Rate_Range_Supplement_New_20221206.pdf' }]});
  // common.push({ links: [ { label: 'December Funds Rate Range Supplement (Resetting Funds)', link: 'https://assets.millimanfunds.com/December_Funds_Rate_Range_Supplement_Reset_20221206.pdf' }]});
  // common.push({ links: [ { label: 'December Funds Rate Range Supplement (Trigger Funds)', link: 'https://assets.millimanfunds.com/December_Funds_Rate_Range_Supplement_Trigger_20221206.pdf' }]});
  resources.push({ id: 'general', title: 'General', links: common.map(r => ({ label: r.links[0].label, link: r.links[0].link })) });

  const unique = groupedArray.filter(r => r.links.length === 1);
  const processed = {};
  const multiples = unique.map(u => ({ 
    id: u.links[0].fund.fundId || u.links[0].fund.otherFundId,
    title: u.links[0].fund.name || `${u.links[0].fund.strategyName} - ${u.links[0].fund.resetMonthLabel}`,
    links: [{ label: u.links[0].label, link: u.links[0].link }]
  }));
  
  for (let i = 0; i < multiples.length; ++i) {
    let group = processed[multiples[i].title];
    if (!group) {
      processed[multiples[i].title] = multiples[i];
      resources.push(multiples[i]);
    }
    else {
      group.links.push(...multiples[i].links);
    }
  }

  return resources;
}

const ResourcesPage = ({ data }) => {
  const resources = useRef(getResources(data));

  if (process.env.GATSBY_CAPGROUP_FUNDS !== 'true')
    return null;

  return <CapGroupPageLayout id="resources" title="Resources" className="page-resources">
    <Container>
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Panel>
          <h2>Resources</h2>

          {resources.current.map(r => <Row key={r.id} className="mt-3">
              <Col>
                <span style={{fontWeight: '700'}}>{r.title}</span>
                <p>
                  {r.links.map((l, i) => <span key={`${r.id}_${i}`}>
                    {i > 0 ? <br /> : null}
                    <a href={l.link} target="_blank" rel="noopener noreferrer nofollow">{l.label}</a>
                  </span>)}
                </p>
              </Col>
            </Row>)}

        </Panel>

        <Disclosures isCapGroup />
      </div>
    </Container>
  </CapGroupPageLayout>
}

export const query = graphql`
  {
    allFund(filter: {strategySeries: {eq: "capgroup"}}) {
      edges {
        node {
          fundId,
          strategyId,
          strategyName,
          strategyDescription,
          resetMonth,
          resetMonthLabel,
          sortIndex,
          resourceLinks {
            label
            link
            sortIndex
          }
        }
      }
    }

    allOtherFund {
      edges {
        node {
          fundId,
          name,
          description,
          sortIndex,
          resourceLinks {
            label
            link
            sortIndex
          }
        }
      }
    }
  }
`

export default ResourcesPage
